import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconArrowRight.module.css';

const IconArrowRight = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

IconArrowRight.defaultProps = {
  className: null,
  rootClassName: null,
};

IconArrowRight.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconArrowRight;
