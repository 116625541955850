import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconDetail.module.css';

const IconDetail = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} viewBox="0 0 24 24" width="18" height="18" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 3H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-9 14H5v-2h6v2zm8-4H5v-2h14v2zm0-4H5V7h14v2z"/>
    </svg>
  );
};

const { string } = PropTypes;

IconDetail.defaultProps = {
  className: null,
  rootClassName: null,
};

IconDetail.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDetail;
