import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const { text, heading, mainHeading, subHeading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  return text ? (
    !!mainHeading || !!subHeading ? (
      <>
        {!!mainHeading ? (
          <section className={css.sectionText}>
            <Heading as="h2" rootClassName={css.sectionHeading}>
              {mainHeading}
            </Heading>
          </section>
        ) : null}
        <section className={classNames(css.sectionText, css.subSectionText)}>
          {!!subHeading ? (
            <Heading as="h2" rootClassName={css.sectionHeading}>
              {subHeading}
            </Heading>
          ) : null}
          <p className={textClass}>{content}</p>
        </section>
      </>
    ) : (
      <section className={css.sectionText}>
        {heading ? (
          <Heading as="h2" rootClassName={css.sectionHeading}>
            {heading}
          </Heading>
        ) : null}
        <p className={textClass}>{content}</p>
      </section>
    )
  ) : null;
};

export default SectionTextMaybe;
