import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBuy.module.css';

const IconBuy = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      viewBox="0 0 32 32"
      width="18"
      height="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="stone_een"
        d="M27.857,27.864C27.935,29.572,26.571,31,24.861,31H7.139c-1.71,0-3.075-1.428-2.997-3.136L4.957,9.955
	C4.981,9.42,5.421,9,5.956,9h20.089c0.535,0,0.975,0.42,0.999,0.955L27.857,27.864z M16.185,1.003C12.787,0.901,10,3.625,10,7v1h2V7
	c0-2.209,1.791-4,4-4s4,1.791,4,4v1h2V7.252C22,3.966,19.468,1.101,16.185,1.003z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconBuy.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBuy.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBuy;
