import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconOffer.module.css';

const IconOffer = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0" />

      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

      <g id="SVGRepo_iconCarrier">
        <path
          d="M8 8H8.01M11.5858 4.58579L19.5858 12.5858C20.3668 13.3668 20.3668 14.6332 19.5858 15.4142L15.4142 19.5858C14.6332 20.3668 13.3668 20.3668 12.5858 19.5858L4.58579 11.5858C4.21071 11.2107 4 10.702 4 10.1716V6C4 4.89543 4.89543 4 6 4H10.1716C10.702 4 11.2107 4.21071 11.5858 4.58579Z"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconOffer.defaultProps = {
  className: null,
  rootClassName: null,
};

IconOffer.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconOffer;
