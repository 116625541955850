import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, AvatarSmall } from '../../components';
import SectionTextMaybe from './SectionTextMaybe';
import truncate from 'lodash/truncate';
import { string } from 'prop-types';
import { displayPrice } from '../../util/configHelpers';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { convertPriceToUserCurrency } from '../../util/currency';
const { Money } = sdkTypes;

import css from './ListingPage.module.css';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the author section of the ListingPage.
const DESCRIPTION_COLLAPSED_LENGTH = 170;

const truncated = s => {
  return truncate(s, {
    length: DESCRIPTION_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const ExpandableDescription = props => {
  const [expand, setExpand] = useState(false);
  const { className, description } = props;
  const truncatedDescription = truncated(description);
  const descriptionText = expand ? description : truncatedDescription;
  const handleShowMoreClick = () => {
    setExpand(!expand);
  };
  const showBtn = (
    <InlineTextButton rootClassName={css.showBtn} onClick={handleShowMoreClick}>
      <FormattedMessage id={expand ? 'OrderPanel.showLess' : 'OrderPanel.showMore'} />
    </InlineTextButton>
  );
  return (
    <p className={className}>
      <SectionTextMaybe text={descriptionText} showAsIngress />
      {description !== truncatedDescription ? showBtn : null}
    </p>
  );
};

ExpandableDescription.defaultProps = { className: null };

ExpandableDescription.propTypes = {
  className: string,
  description: string.isRequired,
};

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    label,
    showCurrencyMismatch = false,
    priceInUserCurrency,
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceLabel}>{label}</div>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <div className={css.priceContainer}>
      <div className={css.priceLabel}>{label}</div>
      <p className={css.price}>
        {formatMoney(intl, price)}
        {!!priceInUserCurrency ? (
          <span className={css.priceInUserCurrency}>
            {` (${intl.formatMessage({ id: 'OrderPanel.approx' })} ${formatMoney(
              intl,
              priceInUserCurrency
            )})`}
          </span>
        ) : null}
      </p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const SectionDetailsMaybe = props => {
  const {
    listing,
    author,
    authorLink,
    listingConfig: listingConfigUnsorted,
    intl,
    marketplaceCurrency,
    onContactUser,
    currentUser,
    currencyRates,
  } = props;
  const { price, description = '', publicData } = listing?.attributes || {};
  const { listingTypes } = listingConfigUnsorted || {};
  const hasDescription = !!description;

  if (!publicData || !listingConfigUnsorted) {
    return null;
  }

  const { originalPrice } = publicData || {};

  const originalPriceFormatted = !!originalPrice
    ? new Money(originalPrice.amount, originalPrice.currency)
    : null;
  const contact = (
    <InlineTextButton
      rootClassName={css.contact}
      onClick={() => onContactUser(author)}
      enforcePagePreloadFor="SignupPage"
    >
      <FormattedMessage id="OrderPanel.contactUser" />
    </InlineTextButton>
  );
  const userCurrency = currentUser?.attributes?.profile?.privateData?.currency;
  const priceInUserCurrency =
    !userCurrency || userCurrency === price?.currency
      ? null
      : convertPriceToUserCurrency(price, userCurrency, currencyRates);
  const originalPriceInUserCurrency =
    !userCurrency || userCurrency === originalPriceFormatted?.currency
      ? null
      : convertPriceToUserCurrency(originalPriceFormatted, userCurrency, currencyRates);
  return (
    <section className={css.sectionDetails}>
      <PriceMaybe
        price={originalPriceFormatted}
        publicData={publicData}
        validListingTypes={listingTypes}
        intl={intl}
        marketplaceCurrency={marketplaceCurrency}
        label={intl.formatMessage({ id: 'OrderPanel.originalPriceLabel' })}
        priceInUserCurrency={originalPriceInUserCurrency}
      />

      <PriceMaybe
        price={price}
        publicData={publicData}
        validListingTypes={listingTypes}
        intl={intl}
        marketplaceCurrency={marketplaceCurrency}
        label={intl.formatMessage({ id: 'OrderPanel.priceLabel' })}
        priceInUserCurrency={priceInUserCurrency}
      />

      <div className={css.author}>
        <div className={css.providerLinkWrapper}>
          <AvatarSmall user={author} className={css.providerAvatar} />
          <span className={css.providerNameLinked}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
          </span>
        </div>
        <div className={css.contactSeller}>{contact}</div>
      </div>
      {hasDescription ? (
        <ExpandableDescription className={css.description} description={description} />
      ) : null}
    </section>
  );
};

export default SectionDetailsMaybe;
