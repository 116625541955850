import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import SectionTextMaybe from './SectionTextMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import { SCHEMA_TYPE_MULTI_ENUM, SCHEMA_TYPE_TEXT } from '../../util/types';
import sortby from 'lodash/sortBy';
import { Heading, IconDetail } from '../../components';


import css from './ListingPage.module.css';

const SectionListingDetailsMaybe = props => {
  const { listing, listingConfig: listingConfigUnsorted, intl } = props;
  const { publicData, metadata = {} } = listing?.attributes || {};
  const { listingFields, listingTypes } = listingConfigUnsorted || {};
  if (!publicData || !listingConfigUnsorted) {
    return null;
  }

  let hasIncludedSizeDetailHeading = false;
  let listingConfig = listingConfigUnsorted;
  listingConfig.listingFields = sortby(listingConfig.listingFields, [
    function(f) {
      return f?.position || 0;
    },
  ]);

  const { listingType, subCategory, group } = publicData || {};
  const selectedCategoryConfig = listingTypes?.find(f => f.listingType === listingType);
  const subCategoryConfigs = listingFields?.find(f => f.key === 'subCategory');
  const selectedSubCategoryConfig = listingType
    ? subCategoryConfigs?.enumOptions?.find(o => o.parentKey === listingType)
    : null;
  const selectedSubCategoryOptionConfig = selectedSubCategoryConfig?.options?.find(
    f => f.option === subCategory
  );
  const groupConfig = selectedSubCategoryOptionConfig?.options?.find(f => f.value === group);
  const categoryLabel = !!selectedCategoryConfig?.label ? selectedCategoryConfig?.label : '';
  const subCategoryLabel = !!selectedSubCategoryOptionConfig?.label
    ? selectedSubCategoryOptionConfig?.label
    : '';
  const groupLabel = !!groupConfig?.label ? groupConfig?.label : '';
  let category = categoryLabel;
  if (!!subCategoryLabel) category += ' • ' + subCategoryLabel;
  if (!!groupLabel) category += ' • ' + groupLabel;
  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, includeForListingTypes, showConfig = {} } = config;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(listingType);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFields.reduce(pickListingFields, []);
  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));
  return existingListingFields.length > 0 ? (
    <section className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <IconDetail />
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        <li key={'category'} className={css.detailsRow}>
          <span className={css.detailLabel}>
            <FormattedMessage id="ListingPage.category" />
          </span>
          <span>{category}</span>
        </li>
      </ul>
      <ul className={css.details}>
        {existingListingFields.map(detail => (
          <li key={detail.key} className={css.detailsRow}>
            <span className={css.detailLabel}>{detail.label}:</span>
            <span>{detail.value}</span>
          </li>
        ))}
      </ul>
      {listingConfig.listingFields.reduce((pickedElements, config) => {
        const { key, enumOptions, includeForListingTypes, scope = 'public' } = config;
        const listingType = publicData?.listingType;
        const isTargetListingType =
          includeForListingTypes == null || includeForListingTypes.includes(listingType);

        const value =
          scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
        const hasValue = value != null;
        let heading = config?.showConfig?.label;
        let mainHeading = null;
        let subHeading = null;
        const isSizeDetail = heading?.toLocaleLowerCase()?.includes('size detail:');

        if (isSizeDetail && hasValue && hasIncludedSizeDetailHeading) {
          subHeading = heading?.split(':')?.[1];
          subHeading = !!subHeading && typeof subHeading === 'string' ? subHeading + ':' : null;
        } else if (isSizeDetail && hasValue) {
          hasIncludedSizeDetailHeading = true;
          [mainHeading, subHeading] = heading?.split(':');
          subHeading = !!subHeading && typeof subHeading === 'string' ? subHeading + ':' : null;
          mainHeading = !!mainHeading && typeof mainHeading === 'string' ? mainHeading + ':' : null;
        }
        return isTargetListingType && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
          ? [
              ...pickedElements,
              <SectionMultiEnumMaybe
                key={key}
                heading={heading}
                options={createFilterOptions(enumOptions)}
                selectedOptions={value || []}
              />,
            ]
          : isTargetListingType && hasValue && config.schemaType === SCHEMA_TYPE_TEXT
          ? [
              ...pickedElements,
              <SectionTextMaybe
                key={key}
                heading={heading}
                mainHeading={mainHeading}
                subHeading={subHeading}
                text={value}
              />,
            ]
          : pickedElements;
      }, [])}
    </section>
  ) : null;
};

export default SectionListingDetailsMaybe;
