import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconInbox.module.css';

const IconInbox = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} viewBox="0 0 48 48" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.47,10.71a2,2,0,0,0-2,2h0V35.32a2,2,0,0,0,2,2H41.53a2,2,0,0,0,2-2h0V12.68a2,2,0,0,0-2-2H6.47Zm33.21,3.82L24,26.07,8.32,14.53"/>
    </svg>
  );
};

IconInbox.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconInbox.propTypes = { rootClassName: string, className: string };

export default IconInbox;
