/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'subCategory',
    subkey: 'group',
    scope: 'public',
    includeForListingTypes: ['women', 'men', 'kids', 'accessories'],
    schemaType: 'nested-enum',
    nestedEnumOptions: [
      {
        parentKey: 'women',
        options: [
          {
            option: 'Sarees',
            label: 'Sarees',
          },
          {
            option: 'Traditional Silk Sarees',
            label: 'Traditional Silk Sarees',
          },
          {
            option: 'Pre-Stitched/Draped Sarees',
            label: 'Pre-Stitched/Draped Sarees',
          },
          {
            option: 'Lehengas',
            label: 'Lehengas',
          },
          {
            option: 'Anarkalis',
            label: 'Anarkalis',
          },
          {
            option: 'Salwar Kameez',
            label: 'Salwar Kameez',
          },
          {
            option: 'Churidaars',
            label: 'Churidaars',
          },
          {
            option: 'Pant Sets',
            label: 'Pant Sets',
          },
          {
            option: 'Shararas',
            label: 'Shararas',
          },
          {
            option: 'Dresses/Gowns',
            label: 'Dresses/Gowns',
          },
          {
            option: 'Jumpsuits',
            label: 'Jumpsuits',
          },
          {
            option: 'Kaftans',
            label: 'Kaftans',
          },
          {
            option: 'Blouses',
            label: 'Blouses',
          },
          {
            option: 'Tunics',
            label: 'Tunics',
          },
          {
            option: 'Dupattas',
            label: 'Dupattas',
          },
          {
            option: 'Pants/Skirts',
            label: 'Pants/Skirts',
          },
          {
            option: 'Lounge',
            label: 'Lounge',
          },
          {
            option: 'Co-Ord Sets',
            label: 'Co-Ord Sets',
          },
          {
            option: 'Indo-Western/Cape/Dhoti',
            label: 'Indo-Western/Cape/Dhoti',
          },
          {
            option: 'Maternity',
            label: 'Maternity',
          },
          {
            option: 'Mommy & Me',
            label: 'Mommy & Me',
          },
          {
            option: 'Other',
            label: 'Other',
          },
        ],
      },
      {
        parentKey: 'men',
        options: [
          {
            option: 'Kurtas',
            label: 'Kurtas',
          },
          {
            option: 'Kurta Sets',
            label: 'Kurta Sets',
          },
          {
            option: 'Sherwanis',
            label: 'Sherwanis',
          },
          {
            option: 'Waist Coats',
            label: 'Waist Coats',
          },
          {
            option: 'Shirts',
            label: 'Shirts',
          },
          {
            option: 'Nehru Jackets/Suits',
            label: 'Nehru Jackets/Suits',
          },
          {
            option: 'Other',
            label: 'Other',
          },
        ],
      },
      {
        parentKey: 'kids',
        options: [
          {
            option: 'girls',
            label: 'Girls',
            options: [
              { value: 'Sarees/Drape Sarees', label: 'Sarees/Drape Sarees' },
              { value: 'Lehengas', label: 'Lehengas' },
              { value: 'Anarkalis/Kurta Sets', label: 'Anarkalis/Kurta Sets' },
              { value: 'Kurtas/Tops', label: 'Kurtas/Tops' },
              { value: 'Gowns/Dresses', label: 'Gowns/Dresses' },
              { value: 'Mommy & Me', label: 'Mommy & Me' },
              { value: 'Other', label: 'Other' },
            ],
          },
          {
            option: 'boys',
            label: 'Boys',
            options: [
              { value: 'Kurtas', label: 'Kurtas' },
              { value: 'Kurta Sets', label: 'Kurta Sets' },
              { value: 'Sherwani', label: 'Sherwani' },
              { value: 'WaistCoats', label: 'WaistCoats' },
              { value: 'Shirts', label: 'Shirts' },
              { value: 'Nehru Jackets/Suits', label: 'Nehru Jackets/Suits' },
              { value: 'Daddy & Me', label: 'Daddy & Me' },
              { value: 'Other', label: 'Other' },
            ],
          },
        ],
      },
      {
        parentKey: 'accessories',
        options: [
          {
            option: 'Jewelry',
            label: 'Jewelry',
            options: [
              { value: 'Necklaces', label: 'Necklaces' },
              { value: 'Earrings', label: 'Earrings' },
              { value: 'Bangles', label: 'Bangles' },
              { value: 'Tikka', label: 'Tikka' },
              { value: 'Sets', label: 'Sets' },
              { value: 'Anklets', label: 'Anklets' },
            ],
          },
          {
            option: 'Bags',
            label: 'Bags',
            options: [],
          },
          {
            option: 'Belts',
            label: 'Belts',
            options: [],
          },
          {
            option: 'Footwear',
            label: 'Footwear',
            options: [],
          },
          {
            option: 'Shawls/Scarves',
            label: 'Shawls/Scarves',
            options: [],
          },
          {
            option: 'Decor',
            label: 'Decor',
            options: [],
          },
          {
            option: 'Favors',
            label: 'Favors',
            options: [],
          },
        ],
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Subcategory',
      group: 'primary',
      // searchMode: 'has_any',
    },
    showConfig: {
      label: 'Subcategory',
      isDetail: true,
    },
    saveConfig: {
      label: 'Subcategory*',
      placeholderMessage: 'Select subcategory…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'condition',
    scope: 'public',
    schemaType: 'enum',
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Condition',
      group: 'secondary',
    },
    showConfig: {
      label: 'Condition',
    },
    saveConfig: {
      label: 'Condition*',
      placeholderMessage: 'Select condition...',
      isRequired: true,
      requiredMessage: 'You need to select condition.',
    },
    enumOptions: [
      { option: 'New', label: 'New' },
      { option: 'Preloved', label: 'Preloved' },
    ],
  },
  // Women specific fields
  {
    key: 'sizeWomen',
    scope: 'public',
    includeForListingTypes: ['women'],
    schemaType: 'multi-enum',
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
      label: 'Size',
      group: 'primary',
    },
    showConfig: {
      label: 'Size',
    },
    saveConfig: {
      label: 'Size*',
      placeholderMessage: 'Select size...',
      isRequired: true,
      requiredMessage: 'You need to select size.',
    },
    enumOptions: [
      { option: 'XS/32', label: 'XS/32' },
      { option: 'S/34', label: 'S/34' },
      { option: 'M/36', label: 'M/36' },
      { option: 'L/38', label: 'L/38' },
      { option: 'XL/40', label: 'XL/40' },
      { option: 'XXL/42+', label: 'XXL/42+' },
      { option: 'Free Size', label: 'Free Size' },
    ],
  },
  {
    key: 'Size detail: Bust/Chest',
    scope: 'public',
    includeForListingTypes: ['women'],
    schemaType: 'text',
    showConfig: {
      label: 'Size detail: Bust/Chest',
    },
    saveConfig: {
      label: 'Size detail: Bust/Chest',
    },
  },
  {
    key: 'Size Detail: Waist',
    scope: 'public',
    includeForListingTypes: ['women'],
    schemaType: 'text',
    showConfig: {
      label: 'Size Detail: Waist',
    },
    saveConfig: {
      label: 'Size Detail: Waist',
    },
  },
  {
    key: 'Size Detail: Hips',
    scope: 'public',
    includeForListingTypes: ['women'],
    schemaType: 'text',
    showConfig: {
      label: 'Size Detail: Hips',
    },
    saveConfig: {
      label: 'Size Detail: Hips',
    },
  },
  {
    key: 'Size Detail: Length',
    scope: 'public',
    includeForListingTypes: ['women'],
    schemaType: 'text',
    showConfig: {
      label: 'Size Detail: Length',
    },
    saveConfig: {
      label: 'Size Detail: Length',
    },
  },
  {
    key: 'Room to open?',
    scope: 'public',
    includeForListingTypes: ['women'],
    schemaType: 'text',
    showConfig: {
      label: 'Room to open?:',
    },
    saveConfig: {
      label: 'Room to open?',
    },
  },
  {
    key: 'color',
    scope: 'public',
    schemaType: 'multi-enum',
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
      label: 'Color',
      group: 'primary',
    },
    showConfig: {
      label: 'Color',
    },
    saveConfig: {
      label: 'Color',
    },
    enumOptions: [
      { option: 'Beige', label: 'Beige' },
      { option: 'Black', label: 'Black' },
      { option: 'Blue', label: 'Blue' },
      { option: 'Brown', label: 'Brown' },
      { option: 'Champagne', label: 'Champagne' },
      { option: 'Copper', label: 'Copper' },
      { option: 'Ivory', label: 'Ivory' },
      { option: 'Gold', label: 'Gold' },
      { option: 'Green', label: 'Green' },
      { option: 'Gray', label: 'Gray' },
      { option: 'Hot pink', label: 'Hot pink' },
      { option: 'Marigold', label: 'Marigold' },
      { option: 'Metallic', label: 'Metallic' },
      { option: 'Multicolored', label: 'Multicolored' },
      { option: 'Navy', label: 'Navy' },
      { option: 'Orange', label: 'Orange' },
      { option: 'Peach', label: 'Peach' },
      { option: 'Pink', label: 'Pink' },
      { option: 'Purple', label: 'Purple' },
      { option: 'Red', label: 'Red' },
      { option: 'Rust', label: 'Rust' },
      { option: 'Silver', label: 'Silver' },
      { option: 'Teal', label: 'Teal' },
      { option: 'Yellow', label: 'Yellow' },
      { option: 'White', label: 'White' },
      { option: 'Other', label: 'Other' },
    ],
  },
  {
    key: 'designer',
    scope: 'public',
    schemaType: 'enum',
    position: -1,
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
      label: 'Designer',
      group: 'primary',
    },
    showConfig: {
      label: 'Designer',
    },
    saveConfig: {
      label: 'Designer',
    },
    enumOptions: [
      { option: 'Sabyasachi', label: 'Sabyasachi' },
      { option: 'Seema Gujral', label: 'Seema Gujral' },
      { option: 'Ritu Kumar', label: 'Ritu Kumar' },
      { option: 'Anushree Reddy', label: 'Anushree Reddy' },
      { option: 'Manish Malhotra', label: 'Manish Malhotra' },
      { option: 'Papa Don’t Preach', label: 'Papa Don’t Preach' },
      { option: 'Anita Dongre', label: 'Anita Dongre' },
      { option: 'Anamika Khanna', label: 'Anamika Khanna' },
      { option: 'Arpita Mehta', label: 'Arpita Mehta' },
      { option: 'Label Anushree/Anushree Reddy', label: 'Label Anushree/Anushree Reddy' },
      { option: 'Mahima Mahajan', label: 'Mahima Mahajan' },
      { option: 'Chamee & Palak', label: 'Chamee & Palak' },
      { option: 'House of Masaba', label: 'House of Masaba' },
      { option: 'Paulmi & Harsh', label: 'Paulmi & Harsh' },
      { option: 'Payal Singhal', label: 'Payal Singhal' },
      { option: 'Vvani by Vani Vats', label: 'Vvani by Vani Vats' },
      { option: 'Gaurav Gupta', label: 'Gaurav Gupta' },
      { option: 'Debyani', label: 'Debyani' },
      { option: 'Rohit Bal', label: 'Rohit Bal' },
      { option: 'Varun Bahl', label: 'Varun Bahl' },
      { option: 'Dolly J', label: 'Dolly J' },
      { option: 'Ridhi Mehra', label: 'Ridhi Mehra' },
      { option: 'Aisha Rao', label: 'Aisha Rao' },
      { option: 'Jade by Monica & Karishma', label: 'Jade by Monica & Karishma' },
      { option: 'Torani', label: 'Torani' },
      { option: 'Payal Pratap', label: 'Payal Pratap' },
      { option: 'Ritika Mirchandani', label: 'Ritika Mirchandani' },
      { option: 'Bhumika Sharma', label: 'Bhumika Sharma' },
      { option: 'Mishru', label: 'Mishru' },
      { option: 'Saaksha & Kinni', label: 'Saaksha & Kinni' },
      { option: 'Gopi Vaid', label: 'Gopi Vaid' },
      { option: 'Tamanna Punjabi Kapoor', label: 'Tamanna Punjabi Kapoor' },
      { option: 'Ekaya', label: 'Ekaya' },
      { option: 'ITRH', label: 'ITRH' },
      { option: 'Rajdeep Ranawat', label: 'Rajdeep Ranawat' },
      { option: 'Ranna Gill', label: 'Ranna Gill' },
      { option: 'Megha & Jiggar', label: 'Megha & Jiggar' },
      { option: 'Dhristi & Zahabia', label: 'Dhristi & Zahabia' },
      { option: 'Other', label: 'Other' },
    ],
  },
  {
    key: 'Material',
    scope: 'public',
    includeForListingTypes: ['women'],
    schemaType: 'text',
    showConfig: {
      label: 'Material:',
    },
    saveConfig: {
      label: 'Material',
      placeholderMessage: 'If known. Examples: Georgette, net, orgazna, silk, crepe, etc.',
    },
  },
  {
    key: 'Padded?',
    scope: 'public',
    includeForListingTypes: ['women'],
    schemaType: 'enum',
    showConfig: {
      label: 'Padded?',
    },
    saveConfig: {
      label: 'Padded?',
    },
    enumOptions: [
      { option: 'Yes', label: 'Yes' },
      { option: 'No', label: 'No' },
    ],
  },
  {
    key: 'bridalGroomOutfit',
    scope: 'public',
    schemaType: 'enum',
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Bridal/Groom Outfit?',
      group: 'secondary',
    },
    showConfig: {
      label: 'Bridal/Groom Outfit?',
    },
    saveConfig: {
      label: 'Bridal/Groom Outfit?',
    },
    enumOptions: [
      { option: 'Yes', label: 'Yes' },
      { option: 'No', label: 'No' },
    ],
  },
  // Men specific fields
  {
    key: 'sizeMen',
    scope: 'public',
    includeForListingTypes: ['men'],
    schemaType: 'multi-enum',
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
      label: 'Size',
      group: 'primary',
    },
    showConfig: {
      label: 'Size',
    },
    saveConfig: {
      label: 'Size*',
      placeholderMessage: 'Select size...',
      isRequired: true,
      requiredMessage: 'You need to select size.',
    },
    enumOptions: [
      { option: 'XS/34', label: 'XS/34' },
      { option: 'S/36', label: 'S/36' },
      { option: 'M/38', label: 'M/38' },
      { option: 'L/40', label: 'L/40' },
      { option: 'XL/42', label: 'XL/42' },
      { option: 'XXL/44+', label: 'XXL/44+' },
      { option: 'Free Size', label: 'Free Size' },
    ],
  },
  {
    key: 'Size detail: Chest (men)',
    scope: 'public',
    includeForListingTypes: ['men'],
    schemaType: 'text',
    showConfig: {
      label: 'Size detail: Chest',
    },
    saveConfig: {
      label: 'Size detail: Chest',
    },
  },
  {
    key: 'Size Detail: Waist (men)',
    scope: 'public',
    includeForListingTypes: ['men'],
    schemaType: 'text',
    showConfig: {
      label: 'Size Detail: Waist',
    },
    saveConfig: {
      label: 'Size Detail: Waist',
    },
  },
  {
    key: 'Size Detail: Length (men)',
    scope: 'public',
    includeForListingTypes: ['men'],
    schemaType: 'text',
    showConfig: {
      label: 'Size Detail: Length',
    },
    saveConfig: {
      label: 'Size Detail: Length',
    },
  },
  {
    key: 'Room to open? (men)',
    scope: 'public',
    includeForListingTypes: ['men'],
    schemaType: 'text',
    showConfig: {
      label: 'Room to open?:',
    },
    saveConfig: {
      label: 'Room to open?',
    },
  },
  {
    key: 'Material (men)',
    scope: 'public',
    includeForListingTypes: ['men'],
    schemaType: 'text',
    showConfig: {
      label: 'Material:',
    },
    saveConfig: {
      label: 'Material',
      placeholderMessage: 'If known. Examples: Georgette, net, orgazna, silk, crepe, etc.',
    },
  },
  {
    key: 'sizeKids',
    scope: 'public',
    includeForListingTypes: ['kids'],
    schemaType: 'multi-enum',
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
      label: 'Size',
      group: 'primary',
    },
    showConfig: {
      label: 'Size',
    },
    saveConfig: {
      label: 'Size*',
      placeholderMessage: 'Select size...',
      isRequired: true,
      requiredMessage: 'You need to select size.',
    },
    enumOptions: [
      { option: '> 1y', label: '> 1y' },
      { option: '2-4y', label: '2-4y' },
      { option: '5-7y', label: '5-7y' },
      { option: '8-10y', label: '8-10y' },
      { option: '11-13y', label: '11-13y' },
      { option: '14-16y', label: '14-16y' },
      { option: 'Free Size', label: 'Free Size' },
    ],
  },
  {
    key: 'Size detail: Chest (kids)',
    scope: 'public',
    includeForListingTypes: ['kids'],
    schemaType: 'text',
    showConfig: {
      label: 'Size detail: Chest',
    },
    saveConfig: {
      label: 'Size detail: Chest',
    },
  },
  {
    key: 'Size Detail: Waist (kids)',
    scope: 'public',
    includeForListingTypes: ['kids'],
    schemaType: 'text',
    showConfig: {
      label: 'Size Detail: Waist',
    },
    saveConfig: {
      label: 'Size Detail: Waist',
    },
  },
  {
    key: 'Size Detail: Length (kids)',
    scope: 'public',
    includeForListingTypes: ['kids'],
    schemaType: 'text',
    showConfig: {
      label: 'Size Detail: Length',
    },
    saveConfig: {
      label: 'Size Detail: Length',
    },
  },
  {
    key: 'Room to open? (kids)',
    scope: 'public',
    includeForListingTypes: ['kids'],
    schemaType: 'text',
    showConfig: {
      label: 'Room to open?:',
    },
    saveConfig: {
      label: 'Room to open?',
    },
  },
  {
    key: 'Material (kids)',
    scope: 'public',
    includeForListingTypes: ['kids'],
    schemaType: 'text',
    showConfig: {
      label: 'Material:',
    },
    saveConfig: {
      label: 'Material',
      placeholderMessage: 'If known. Examples: Georgette, net, orgazna, silk, crepe, etc.',
    },
  },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  {
    listingType: 'women',
    label: 'Women',
    stockType: 'multipleItems',
    transactionType: {
      process: 'negotiated-purchase',
      alias: 'negotiated-purchase/release-1',
      unitType: 'item',
    },
    defaultListingFields: {
      shipping: true,
      pickup: true,
      payoutDetails: false,
      price: true,
    },
  },
  {
    listingType: 'men',
    label: 'Men',
    stockType: 'multipleItems',
    transactionType: {
      process: 'negotiated-purchase',
      alias: 'negotiated-purchase/release-1',
      unitType: 'item',
    },
    defaultListingFields: {
      shipping: true,
      pickup: true,
      payoutDetails: false,
      price: true,
    },
  },
  {
    listingType: 'kids',
    label: 'Kids',
    stockType: 'multipleItems',
    transactionType: {
      process: 'negotiated-purchase',
      alias: 'negotiated-purchase/release-1',
      unitType: 'item',
    },
    defaultListingFields: {
      shipping: true,
      pickup: true,
      payoutDetails: false,
      price: true,
    },
  },
  {
    listingType: 'accessories',
    label: 'Accessories',
    stockType: 'multipleItems',
    transactionType: {
      process: 'negotiated-purchase',
      alias: 'negotiated-purchase/release-1',
      unitType: 'item',
    },
    defaultListingFields: {
      shipping: true,
      pickup: true,
      payoutDetails: false,
      price: true,
    },
  },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
