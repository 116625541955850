import pick from 'lodash/pick';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/PayoutPage/SET_INITIAL_VALUES';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/PayoutPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/PayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/PayoutPage/SAVE_PAYOUT_DETAILS_ERROR';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaveError: null,
  payoutDetailsSaved: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true, payoutDetailsSaveError: null };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaveError: payload };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = (payload) => ({
  type: SAVE_PAYOUT_DETAILS_ERROR,
  payload: payload,
});
export const savePayoutDetailsSuccess = (payload) => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
  payload: payload,
});

// ================ Thunks ================ //

export const savePayoutDetails = (payload) => (dispatch, getState, sdk) => {
  dispatch(savePayoutDetailsRequest());
  const queryParams = {
    expand: true,
  };

  return sdk.currentUser
    .updateProfile(payload, queryParams)
    .then(response => {
      dispatch(savePayoutDetailsSuccess(response));

      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];
      dispatch(savePayoutDetailsSuccess(currentUser));
    })
    .catch(e => dispatch(savePayoutDetailsError(storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser()).then(response => {
    return response;
  });
};
